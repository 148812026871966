#motives {
  padding: 0 0 52px;

  .motives__banner {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 16px;

    span:not(.cursive) {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      text-transform: uppercase;
      color: #000000;
    }

    span.cursive {
      font-style: normal;
      font-weight: 400;
      font-size: 80px;
      line-height: 92px;
      text-align: center;
      color: #000000;
    }
  }

  .motives__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-auto-flow: column;
    place-items: stretch;
    gap: 24px 40px;
    max-width: 1246px;
    margin: 0 auto 40px;
    padding: 0 16px;
  }

  .motives__item {
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 16px;
    font-size: 14px;
    line-height: 17px;
  }

  .motives__icon {
    flex: none;
  }

  .signup-btn__container {
    padding: 0 16px;
  }

  .signup-btn {
    max-width: 420px;
    margin: 0 auto;
  }
}

@media (max-width: 1025px) {
  #motives {
    padding: 0 0 26px;

    .motives__banner {
      margin-bottom: 24px;
    }

    h2 {
      display: flex;
      flex-direction: column;

      span.cursive {
        font-size: 45px;
        line-height: 69px;
      }

      span:not(.cursive) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .motives__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .motives__item {
      padding: 12px;
      width: 100%;
    }

    .signup-btn {
      height: 44px;
      max-width: 320px;
      margin: 0 auto;
    }
  }
}
