#stores {
  padding: 52px 0 104px;

  .store {
    display: flex;
    align-items: center;
    gap: 32px;

    &:last-child {
      margin-top: 24px;
      justify-content: right;
    }
  }

  .store__image {
    max-width: 100%;
    height: auto;
  }

  .store__text {
    max-width: 430px;
    padding: 0 16px 0 0;

    span:not(.cursive) {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-transform: uppercase;
      color: #000000;
    }

    span.cursive {
      font-weight: 400;
      font-size: 60px;
      text-align: center;
      color: #000000;
      margin-left: 12px;
    }
  }

  .store:last-child .store__text {
    max-width: 335px;
    padding: 0 0 0 16px;
  }
}

@media (max-width: 1025px) {
  #stores {
    padding: 26px 0 34px;

    .store {
      flex-direction: column;

      &:last-child {
        flex-direction: column-reverse;
        margin-top: 40px;
      }
    }

    .store__image {
      margin: 0 auto 0 0;
    }

    .store__text {
      padding: 0 16px;
    }

    .store:last-child {
      .store__image {
        margin: 0 0 0 auto;
      }

      .store__text {
        margin: 0 auto 0 0;
      }
    }
  }
}

@media (max-width: 640px) {
  #stores {
    .store__image {
      max-width: 250px;
    }

    .store__text {
      margin-left: auto;
      max-width: 360px;

      span:not(.cursive) {
        font-size: 16px;
        line-height: 36px;
      }

      span.cursive {
        font-size: 40px;
        line-height: 24px;
      }
    }
  }
}
