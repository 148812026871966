@font-face {
  font-family: "Jakarta";
  src: local("Jakarta"), url(../fonts/jakarta-webfont.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Hagia Signature";
  src: local("Hagia Signature"),
    url(../fonts/hagia-signature.woff) format("woff");
  font-display: swap;
}

body,
button,
a,
input,
input::placeholder,
textarea,
textarea::placeholder {
  font-family: "Montserrat", sans-serif;
}

.cursive {
  font-family: "Hagia Signature", "Jakarta", "Montserrat", sans-serif;
}
