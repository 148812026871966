@import "reset";
@import "fonts";

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

.content {
  max-width: 1366px;
  margin: 0 auto;
}

// Components
@import "components/button";
@import "components/form";
@import "components/collapsible";

// Sections
@import "sections/intro";
@import "sections/history";
@import "sections/benefits";
@import "sections/motives";
@import "sections/stores";
@import "sections/investment";
@import "sections/footer";
