@import "../components/form";

#intro {
  .intro__form {
    background: url("../../assets/images/banner-form.webp"), #91959a;
    background-repeat: no-repeat;
    background-position: left;
    height: 550px;
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
  }

  .intro__highlight {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
    margin-right: 24px;

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 150px;
      color: #ffffff;
      mix-blend-mode: normal;
      text-transform: lowercase;
      margin-bottom: 40px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      color: #ffffff;
    }
  }

  .intro__text {
    padding: 20px 16px 16px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 123.12%;
      text-align: center;
      color: #000000;
    }

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 92px;
      text-align: center;
      text-transform: lowercase;
      color: #000000;
      margin-top: -15px;
    }
  }
}

@media (max-width: 1025px) {
  #intro {
    .intro__highlight {
      margin: 35px auto 0 0;
      padding: 0 16px;
    }

    .intro__form {
      background: url("../../assets/images/banner-form-mobile.webp");
      background-repeat: no-repeat;
      background-size: 100%;
      flex-direction: column;
      align-items: center;
      height: auto;
      gap: 40px;
      padding: 20px 10px 10px;
    }
  }
}

@media (max-width: 640px) {
  #intro {
    .intro__highlight {
      max-width: 260px;

      h1 {
        font-size: 64px;
        line-height: 80px;
        margin: 0;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }
    }

    .intro__text {
      p {
        font-size: 14px;
        line-height: 123.12%;
      }

      h2 {
        font-size: 45px;
        line-height: 69px;
      }
    }
  }
}
