#investment {
  background: #fdebe9;
  padding: 32px 0;

  .content {
    display: flex;
    justify-content: center;
    gap: 100px;
    padding: 0 16px;
  }

  .investment__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      display: flex;
      flex-direction: column;
      max-width: 438px;
      margin-bottom: 60px;

      span.cursive {
        font-weight: 400;
        font-size: 100px;
        line-height: 110px;
        text-align: center;
        color: #000000;
      }

      span:not(.cursive) {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        text-transform: uppercase;
        color: #000000;
      }
    }

    .signup-btn {
      max-width: 419px;
    }
  }

  .invesment__values {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 436px;

    p {
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;

      &:first-child {
        text-transform: uppercase;
      }

      strong {
        font-weight: 700;
      }

      span {
        margin: 0 10px;
        vertical-align: middle;
      }
    }

    .investment__value {
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
      text-transform: uppercase;
      color: #6c4d4c;
    }

    .investment__currency {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #6c4d4c;
      vertical-align: middle;
    }
  }
}

@media (max-width: 1025px) {
  #investment {
    .content {
      flex-direction: column;
      gap: 0;
    }

    .investment__cta {
      h2 {
        margin: 0 auto 16px;

        span.cursive {
          font-size: 50px;
          line-height: 77px;
        }

        span:not(.cursive) {
          font-size: 18px;
          line-height: 22px;
          text-align: center;
        }
      }
    }

    .invesment__values {
      gap: 16px;
      max-width: 500px;
      margin: 0 auto;

      p {
        font-size: 14px;
        line-height: 17px;
      }

      .investment__value {
        font-size: 32px;
        line-height: 39px;
      }

      .investment__currency {
        font-size: 14px;
        line-height: 17px;
      }
    }

    .signup-btn {
      width: 100%;
      max-width: 320px;
      height: 44px;
    }
  }
}
