.form {
  width: 450px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px 35px;

  .form__title {
    font-style: normal;
    font-weight: 400;
    color: #000000;
    display: flex;

    span:not(.cursive) {
      font-size: 20px;
      line-height: 36px;
      text-transform: uppercase;
    }

    .cursive {
      font-size: 50px;
      line-height: 77px;
    }
  }

  .form__subtitle {
    margin: 16px auto 24px 0;
  }

  .form__fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    input,
    textarea {
      padding: 14.5px 16px;
      background: #fafafa;
      border: 1px solid #efefef;
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;

      &::placeholder {
        color: #000;
      }
    }

    &:last-child {
      gap: 16px;
    }
  }

  .form__group {
    position: relative;

    input {
      width: 100%;
    }

    .form__error {
      position: absolute;
      color: #ff3400;
      bottom: -14px;
      font-size: 12px;
      font-weight: 600;
      left: 2px;
    }

    .form__success {
      position: absolute;
      color: #047c58;
      bottom: -18px;
      font-size: 12px;
      font-weight: 600;
      width: 100%;
      text-align: center;
      left: 0;
    }
  }

  .form__location {
    display: flex;
    gap: 18px;

    input,
    textarea {
      flex-grow: 1;
    }

    textarea {
      width: 100% !important;
      min-height: 47px !important;
      max-height: 47px !important;
      line-height: initial;
      resize: none;
      white-space: nowrap;
      overflow: hidden;
    }

    .dropdown {
      position: relative;
      border-radius: 4px;
      display: flex;
      width: 85px;
    }

    .dropdown__btn {
      $dropdownArrow: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjMzMDI3IDcuMTAzNjJMMTEuODY1MyAxLjU1MzI3QzEyLjA0NjUgMS4zNzE0MiAxMi4wNDYyIDEuMDc3IDExLjg2NDMgMC44OTU0NTNDMTEuNjgyNSAwLjcxNDA0OCAxMS4zODc5IDAuNzE0NTE2IDExLjIwNjUgMC44OTYzOTFMNi4wMDA5NiA2LjExNjM3TDAuNzk1NDEzIDAuODk2MjAzQzAuNjE0MDA4IDAuNzE0MzUyIDAuMzE5NjEgMC43MTM4ODMgMC4xMzc3MzYgMC44OTUyNjZDMC4wOTQzMzUzIDAuOTM4NDQ1IDAuMDU5OTA3OCAwLjk4OTc4NiAwLjAzNjQzNjcgMS4wNDYzM0MwLjAxMjk2NTUgMS4xMDI4NyAwLjAwMDkxNDEzOCAxLjE2MzUgMC4wMDA5Nzc5OTMgMS4yMjQ3M0MwLjAwMDkwMTg2MyAxLjI4NTczIDAuMDEyODU5NiAxLjM0NjE0IDAuMDM2MTY1NiAxLjQwMjUxQzAuMDU5NDcxNiAxLjQ1ODg5IDAuMDkzNjY4NCAxLjUxMDExIDAuMTM2Nzk4IDEuNTUzMjVMNS42NzE2NiA3LjEwMzYyQzUuNzU4OCA3LjE5MTIxIDUuODc3MzkgNy4yNDAzNiA2LjAwMDk2IDcuMjQwMzZDNi4xMjQ1MiA3LjI0MDM2IDYuMjQyOTcgNy4xOTEwNyA2LjMzMDI3IDcuMTAzNjJaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 14.5px 16px;
      font-weight: 400;
      font-size: 12px;
      line-height: initial;
      border-radius: 4px;
      background: #fafafa;
      border: 1px solid #efefef;

      &:focus {
        outline: 2px solid #000;
      }

      &::after {
        content: $dropdownArrow;
      }

      .active::after {
        content: $dropdownArrow;
        transform: rotate(180deg);
      }
    }

    .dropdown__content {
      position: absolute;
      top: 105%;
      left: 0;
      width: 100%;
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
      background: #fafafa;
      max-height: 300px;
      overflow: auto;
      padding: 2px;
      z-index: 1;

      .dropdown__item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 15px;
        padding: 10px;
        cursor: pointer;
        background: #fafafa;
        border-radius: 4px;

        &:hover {
          background: #c9c9c9;
        }

        &:focus {
          outline: 2px solid #000;
        }
      }
    }
  }

  .signup-btn {
    width: 100%;
    min-height: 50px;
    margin-top: 24px;
  }

  .form__policy {
    display: flex;
    align-items: center;
    gap: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;

    #policy {
      width: 20px;
      height: 20px;
      accent-color: #000;
      flex: none;
    }

    label {
      font-size: 12px;
      line-height: 15px;
    }

    a {
      color: #000;
      text-decoration: underline;
    }
  }
}

@media (max-width: 1025px) {
  .form {
    padding: 20px 16px;
    width: 100%;
  }

  .form__policy {
    margin: 0;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 640px) {
  .form {
    .form__title {
      font-size: 16px;
      line-height: 20px;
      gap: 20px;

      span:not(.cursive) {
        font-size: 16px;
        line-height: 20px;
      }

      span.cursive {
        font-size: 40px;
        line-height: 61px;
      }
    }

    .form__subtitle {
      margin: 10px auto 16px 0;
      font-size: 14px;
      line-height: 17px;
    }

    .form__fieldset {
      gap: 16px;

      input,
      textarea {
        padding: 0 12px;
        height: 40px;
      }

      .dropdown__btn {
        padding: 0 12px;
        height: 40px;
      }
    }

    .signup-btn {
      min-height: 44px;
      margin-top: 16px;
    }

    .form__location {
      textarea {
        min-height: 40px !important;
        max-height: 40px !important;
        line-height: 38px;
      }
    }
  }
}
