footer {
  background: #eeeeee;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 0 10px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #000000;
    }
  }
}

@media (max-width: 640px) {
  footer {
    font-size: 10px;
    line-height: 12px;
  }
}
