.signup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background: #000;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  fill: transparent;
  animation: spin 0.7s linear infinite;
}
