#history {
  .content {
    background: linear-gradient(
        270deg,
        #fdebe9 -12.26%,
        rgba(255, 255, 255, 0.1) 49.05%
      ),
      url("../../assets/images/banner-historia.webp");
    background-blend-mode: normal, luminosity;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 74px 16px 85px;
  }

  .history__container {
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 10px;
  }

  .history__col {
    max-width: 521px;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    color: #000000;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;

    span:not(.cursive) {
      font-size: 30px;
      line-height: 37px;
      text-transform: uppercase;
      margin-right: 16px;
    }

    span.cursive {
      font-size: 60px;
      line-height: 92px;
    }
  }

  .history__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 27px;
    text-align: right;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 32px;
  }

  p,
  li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin-bottom: 20px;
  }

  ul {
    list-style: disc;
  }

  li {
    margin: 15px 0;
  }
}

@media (max-width: 1025px) {
  #history {
    .content {
      background: linear-gradient(
          270deg,
          #fdebe9 -12.26%,
          rgba(255, 255, 255, 0.1) 49.05%
        ),
        url("../../assets/images/banner-historia-mobile.webp");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 16px;
    }

    .history__container {
      flex-direction: column;
    }

    .history__col {
      max-width: 100%;
    }

    h2 {
      text-align: center;
    }

    .history__subtitle {
      text-align: center;
    }

    .history__container {
      gap: 0;

      li {
        margin-left: 16px;
      }
    }
  }
}

@media (max-width: 640px) {
  #history {
    h2 {
      span:not(.cursive) {
        font-size: 18px;
        line-height: 22px;
        margin-right: 10px;
      }

      span.cursive {
        font-size: 45px;
        line-height: 69px;
      }
    }

    .history__subtitle {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
